var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"form-row"},[_c('sgv-input-text',{staticClass:"col-6",attrs:{"label":"PLC IP","validations":[
        {text: 'IP Address!', value: _vm.$v.formData.plcIp.$dirty && !_vm.$v.formData.plcIp.ipAddress}
      ]},model:{value:(_vm.formData.plcIp),callback:function ($$v) {_vm.$set(_vm.formData, "plcIp", $$v)},expression:"formData.plcIp"}}),_c('sgv-input-text',{staticClass:"col-6",attrs:{"label":"PLC Port","validations":[
        {text: 'ตัวเลขจำนวนเต็ม!', value: _vm.$v.formData.plcPort.$dirty && !_vm.$v.formData.plcPort.integer}
      ]},model:{value:(_vm.formData.plcPort),callback:function ($$v) {_vm.$set(_vm.formData, "plcPort", $$v)},expression:"formData.plcPort"}}),_c('sgv-input-text',{staticClass:"col-6",attrs:{"label":"Sticker IP","validations":[
        {text: 'IP Address!', value: _vm.$v.formData.stickerIp.$dirty && !_vm.$v.formData.stickerIp.ipAddress}
      ]},model:{value:(_vm.formData.stickerIp),callback:function ($$v) {_vm.$set(_vm.formData, "stickerIp", $$v)},expression:"formData.stickerIp"}}),_c('sgv-input-text',{staticClass:"col-6",attrs:{"label":"Sticker Port","validations":[
        {text: 'ตัวเลขจำนวนเต็ม!', value: _vm.$v.formData.stickerPort.$dirty && !_vm.$v.formData.stickerPort.integer}
      ]},model:{value:(_vm.formData.stickerPort),callback:function ($$v) {_vm.$set(_vm.formData, "stickerPort", $$v)},expression:"formData.stickerPort"}})],1),_c('button',{staticClass:"btn btn-warning",on:{"click":_vm.updateData}},[_vm._v(" แก้ไข ")])])}
var staticRenderFns = []

export { render, staticRenderFns }