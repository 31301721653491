<template>
  <div>
    <div class="form-row">
      <sgv-input-text
        class="col-6"
        label="PLC IP"
        v-model="formData.plcIp"
        :validations="[
          {text: 'IP Address!', value: $v.formData.plcIp.$dirty && !$v.formData.plcIp.ipAddress}
        ]">
      </sgv-input-text>

      <sgv-input-text
        class="col-6"
        label="PLC Port"
        v-model="formData.plcPort"
        :validations="[
          {text: 'ตัวเลขจำนวนเต็ม!', value: $v.formData.plcPort.$dirty && !$v.formData.plcPort.integer}
        ]">
      </sgv-input-text>

      <sgv-input-text
        class="col-6"
        label="Sticker IP"
        v-model="formData.stickerIp"
        :validations="[
          {text: 'IP Address!', value: $v.formData.stickerIp.$dirty && !$v.formData.stickerIp.ipAddress}
        ]">
      </sgv-input-text>

      <sgv-input-text
        class="col-6"
        label="Sticker Port"
        v-model="formData.stickerPort"
        :validations="[
          {text: 'ตัวเลขจำนวนเต็ม!', value: $v.formData.stickerPort.$dirty && !$v.formData.stickerPort.integer}
        ]">
      </sgv-input-text>
    </div>

    <button
      class="btn btn-warning"
      @click="updateData">
      แก้ไข
    </button>
  </div>
</template>

<script>
import { helpers, integer, ipAddress } from 'vuelidate/lib/validators'
import { CONFIG_DEVICE } from './graph'


export default {
  props: {
    deviceId: {
      type: Number,
      required: true
    },
    deviceType: {
      type: String,
      required: true
    },
    templateType: {
      type: String,
      required: true
    },
  },
  data () {
    return {
      formData: {
        plcIp: null,
        plcPort: null,
        stickerIp: null,
        stickerPort: null,
      }
    }
  },
  validations: {
    formData: {
      plcIp: {
        ipAddress: (value) => {
          return !helpers.req(value) || ipAddress(value)
        }
      },
      plcPort: {
        ipAddress: (value) => {
          return !helpers.req(value) || integer(+value)
        }
      },
      stickerIp: {
        ipAddress: (value) => {
          return !helpers.req(value) || ipAddress(value)
        }
      },
      stickerPort: {
        ipAddress: (value) => {
          return !helpers.req(value) || integer(+value)
        }
      },
    }
  },
  methods: {
    updateData () {
      this.$v.formData.$touch()
      if (this.$v.formData.$invalid) return

      this.$apollo.mutate({
        mutation: CONFIG_DEVICE(this.templateType),
        variables: {
          deviceType: this.deviceType,
          deviceId: this.deviceId,
          input: {
            plcIp: this.formData.plcIp,
            plcPort: +this.formData.plcPort,
            stickerIp: this.formData.stickerIp,
            stickerPort: +this.formData.stickerPort,
          }
        }
      }).then(() => {
        this.$toasted.global.success("แก้ไขสำเร็จ")
        this.$v.formData.$reset()
      }).catch(err => {
        this.$toasted.global.error(err)
      })
    }
  }
}
</script>

<style lang="css" scoped>
</style>
